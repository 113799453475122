/**
 * @file Header component used in all pages
 * @author Alwyn Tan
 */

import useCurrentUser from '#/hooks/query/useCurrentUser'
import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Text from '../atoms/Text'
import ProfilePicture from './ProfilePicture'

const Container = styled.div`
  width: 100%;
  padding: 20px 0 10px 0;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
`

type Props = {
  title?: string
  renderTitle?: () => React.ReactNode
  hideProfile: boolean
}

const Header = ({ title, renderTitle, hideProfile }: Props) => {
  const { data: user } = useCurrentUser()

  return (
    <Container>
      <TitleRow>
        {renderTitle ? renderTitle() : title && <Text type="h4">{title}</Text>}
        {!hideProfile && user && (
          <ProfilePicture
            onClick={() => navigate('/me')}
            user={user}
            style={{ position: 'absolute', right: 16 }}
          />
        )}
      </TitleRow>
    </Container>
  )
}

export default Header
