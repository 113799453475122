/**
 * @file 404 Page
 * @author Alwyn Tan
 */

import Text from '#/components/atoms/Text'
import Page from '#/components/templates/Page'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const NotFoundPage = () => {
  useEffect(() => {
    window.analytics.page()
  }, [])

  return (
    <Page>
      <Container>
        <StaticImage
          src="../images/discoman.png"
          alt="discoman"
          height={200}
          width={200}
        />
        <Text type="h3" style={{ marginTop: 20 }}>
          Nothing to See Here 🙃
        </Text>
      </Container>
    </Page>
  )
}

export default NotFoundPage
