/**
 * @file Page Wrapper template, with header, etc
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Header from '../molecules/Header'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ChildContainer = styled.div`
  flex: 1;
  overflow: auto;
`

type Props = {
  title?: string
  renderTitle?: () => React.ReactNode
  hideProfile?: boolean
  children: React.ReactNode
}

const Page = ({ title, renderTitle, hideProfile, children }: Props) => (
  <Container>
    <Header title={title} renderTitle={renderTitle} hideProfile={hideProfile} />
    <ChildContainer>{children}</ChildContainer>
  </Container>
)

export default Page
